
import { textSearch } from "@/assets/ts/_utils";
import {
  ellipsisOverflow,
  toLocaleDateString,
} from "@/assets/ts/_utils/formatters";
import JsonExcel from "@/components/widgets/tables/JsonExcel.vue";
import { AuthModule, ServicioListado } from "@/store/modules";
import moment from "moment";
import { computed, defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import { useStore } from "vuex";
import { getModule } from "vuex-module-decorators";

export default defineComponent({
  name: "servicios-listado",
  components: { "download-excel": JsonExcel },
  props: {
    servicios: Object as () => ServicioListado[] | null,
  },

  setup(props) {
    const { t } = useI18n();
    const router = useRouter();
    const csvLabels = {
      servicioNombre: t("csv.listados.servicio.servicioNombre"),
      inicioPrevistoCSV: t("csv.listados.servicios.inicioPrevisto"),
      finPrevistoCSV: t("csv.listados.servicios.finPrevisto"),
      estado: t("csv.listados.servicios.estado"),
    };

    const store = useStore();
    const authModule = getModule(AuthModule, store);
    const comarcaId = authModule.user.comarcaId;

    const csvFields = [
      "servicioNombre",
      "servicioDescripcion",
      "inicioPrevistoCSV",
      "finPrevistoCSV",
      "estado",
    ];
    const csvName = "serviciosListado" + new Date().getTime() + ".xlsx";

    const search = ref("");

    const handleEdit = (index, row) => {
      router.push(
        `/listados/servicios-programados/${row.servicioProgramadoId}`
      );
    };

    const servicios = computed(() => {
      return props.servicios?.map((ele) => {
        return {
          ...ele,
          servicioNombre: ele.servicioProgramado?.nombre,
          servicioDescripcion: ele.servicioProgramado?.descripcion,
          inicioPrevistoCSV: moment(ele.inicioPrevisto).format(
            "YYYY-MM-DD HH:mm:ss"
          ),
          finPrevistoCSV: moment(ele.finPrevisto).format("YYYY-MM-DD HH:mm:ss"),
        };
      });
    });

    return {
      serviciosListado: computed(() =>
        servicios.value?.filter(
          (data) =>
            !search.value ||
            textSearch(data.servicioProgramado?.nombre, search.value) ||
            textSearch(data.direccion, search.value) ||
            textSearch(data.estado, search.value)
        )
      ),
      comarcaId,
      csvLabels,
      csvFields,
      csvName,
      handleEdit,
      search,
      toLocaleDateString,
      ellipsisOverflow,
    };
  },
});
